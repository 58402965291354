.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1px;
}

.expandable-menu {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    max-height: 0;
  }
  
  .expandable-menu.expanded {
    overflow-y: auto;
    max-height: 500px; 
    background-color: rgb(255, 0, 0);
  }

  .categoryButton {
    height: 50px;
    width: 95vw;
    max-width:400px;
    border: 2px solid rgb(255, 255, 255);
    background-color: black;
    color: whitesmoke;
    border-radius: 8px;
    margin-top: 8px;
    font-size: 28px;
  }

  .categoryButton:focus {
    height: 50px;
    width: 95vw;
    max-width:400px;
    border: 2px solid rgb(255, 255, 255);
    background-color: black;
    color: rgb(255, 0, 0);
    border-radius: 8px;
    margin-top: 8px;
    font-size: 28px;
  }