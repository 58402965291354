.homeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 2vw;

}

.logoImage {
    height: 60vw;
    width: 60vw;
    text-align: center;
    border-radius: 50%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
.content {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
