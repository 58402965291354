.navbar {
    display: flex;
    justify-content: space-evenly;
    border: 4px solid rgb(237, 210, 173);
    border-radius: 20px;
    padding: 4px;
    background-color: black;
    margin: 8px 4px 8px 4px;

}

.nav-link {
    color: rgb(237, 210, 173);
    font-size: 24px;
    font-weight: 500;
    text-decoration: none;
}

.nav-link:focus {
    color: rgb(255, 0, 0);
    font-size: 24px;
    font-weight: 500;
    text-decoration: none;
}

