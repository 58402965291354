

.drinkParent {
    height: 100%;
    overflow: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.drinkHeader {
    text-align: center;
    font-size: 30px;
    border: 4px solid black;
    border-radius: 8px;
    background-color: rgb(237, 210, 173);
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
  }

  .addOns {
    margin-left: 12px;
    margin-right: 12px;
  }