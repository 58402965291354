/* Card.css */
.card {
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 350px;
  margin: 1rem;
}

.card-img {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 2px;
}

.card-name {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.card-price {
  margin: 0;
  font-size: 1rem;
}

